import React from "react";

const BuyMeACoffeeButton = () => {
  return (
    <div >
      <a
        href="https://www.medicalfundraising.net/"
        target="_blank"
        rel="noreferrer"
      >
        <img
          src="/buymeacoffee.jpg"
          alt="RiVirtual"
          style={{
            height: "108px",
            position: "relative",
            display: "inline-block",
          }}
        />
      </a>
    </div>
  );
};

export default BuyMeACoffeeButton;
